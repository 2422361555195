<template>
  <YoTable
    :api-endpoint="apiEndpoint"
    :title-tbl="titleTbl"
    :sub-title-tbl="`${this.$t('River Boat')} TugBoat Jetty 3A`"
    :fields="fields"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    :bottom-label="
      this.$t('KeteranganTable') + '~' + this.$t('Form Land Vehicle') + '~'
    "
    bottom-icon="GitMergeIcon"
    info-page-ability="Manage ( All / TBBM / SAL)"
    placeholder-search="Nama Lengkap"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/pages/components/CompTableT1T2.vue'

export default {
  components: {
    YoTable
  },
  data() {
    return {
      apiEndpoint: 'tug_boat/j3a',
      titleTbl: 'DATA MASTER',
      fields: [
        {
          key: 'name_tug_boat',
          label: 'Nama Kapal',
          stickyColumn: true,
          sortable: true,
          placeholder: 'SAMLAYA 1',
          input: { type: 'input-noarea' },
          searchModel: 'name_tug_boat',
          search: {
            type: 'text'
          }
        },
        {
          key: 'source',
          label: 'Pemilik',
          stickyColumn: true,
          sortable: true,
          placeholder: '',
          input: { type: 'input-noarea' },
          searchModel: 'source',
          search: {
            type: 'text'
          }
        },
        {
          key: 'gt_tug_boat',
          label: 'GT',
          placeholder: 'Hanya Angka',
          sortable: true,
          input: { type: 'number-append', append: '' },
          searchModel: 'gt_tug_boat',
          search: {
            type: 'number'
          }
        },
        {
          key: 'loa_tug_boat',
          label: 'loa (M)',
          placeholder: 'Hanya Angka',
          sortable: true,
          input: { type: 'number-append', append: 'M' },
          searchModel: 'loa_tug_boat',
          search: {
            type: 'number'
          }
        },
        {
          key: 'captain_name_tug_boat',
          label: 'Captain Name',
          sortable: true,
          placeholder: 'Albert sinaga',
          input: { type: 'input-noarea' },
          searchModel: 'captain_name_tug_boat',
          search: {
            type: 'text'
          }
        },
        {
          key: 'captain_telp_tug_boat',
          label: 'Captain TELP',
          placeholder: 'Hanya Angka',
          sortable: true,
          input: { type: 'number' },
          searchModel: 'captain_telp_tug_boat',
          search: {
            type: 'number'
          }
        },
        {
          key: 'user.full_name',
          label: 'Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true },
          searchModel: 'user.full_name',
          search: {
            type: 'text'
          }
        },
        {
          searchModel: 'user.phone',
          search: {
            type: 'number'
          },
          key: 'user.phone',
          label: 'Phone of Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true }
        },
        {
          key: 'created_at',
          label: 'Created AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          },
          searchModel: 'created_at',
          search: {
            type: 'date'
          }
        },
        {
          key: 'updated_at',
          label: 'Updated AT',
          sortable: 'date',
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          },
          searchModel: 'updated_at',
          search: {
            type: 'date'
          }
        },
        {
          key: 'id',
          label: 'Data ID',
          placeholder: 'Hanya Angka',
          sortable: true,
          input: { type: 'number' },
          searchModel: 'id',
          search: {
            type: 'number'
          }
        },
      ]
    }
  },
  computed: {
    newDataTemplate() {
      return {
        full_name: '',
        date_of_birth: moment(),
        no_ktp: '',
        phone: '',
        address: ''
      }
    }
  },
  methods: {
    moment,
    resolveFetchResponse(response) {
      return response.data.tug_boats
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC'
        }
      }
    }
  }
}
</script>
